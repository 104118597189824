<template>
  <div v-if="isLoaded" class="referral-detail">
    <div class="back-top" id="opp-back-top" @click="handleBack">
      <div>
        <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
      </div>
      <h2>Back</h2>
    </div>

    <hr class="good-line" />

    <div class="detail-mid">
      <div class="ref-info">
        <h1>{{ data.title }}</h1>
        <h3>{{ data.company }}</h3>
        <h4>Posted by {{ profile.firstName + " " + profile.lastName }}</h4>
      </div>
      <div class="ref-empty"></div>
      <div class="ref-request">
        <button
          :class="{ withdrawBtn: registerFlag }"
          type="button"
          @click="handleClick"
        >
          <p v-if="!registerFlag">Request a Referral</p>
          <p v-else>Withdraw Request</p>
        </button>
      </div>
    </div>
    <ul class="ref-descriptions">
      <li class="ref-description">
        <h3>Description:</h3>
        <h4>{{ data.description }}</h4>
      </li>
      <li class="ref-description">
        <h3>Deadline:</h3>
        <h4>{{ modifyDeadline(data.deadline) }}</h4>
      </li>
      <li class="ref-description">
        <h3>Limit:</h3>
        <h4>
          The alumnus will accept a maximum of {{ data.limit }} applicants.
        </h4>
        <h4>
          Currently, {{ data.acceptedNum }} out of
          {{ data.applicantsNum }} applicants
          <p v-if="data.acceptedNum > 0">have</p>
          <p v-else>has</p>
          been accepted.
        </h4>
      </li>
    </ul>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import getOpp from "@/composables/Opp/getOpp";
import getProfile from "@/composables/Profile/getProfile";
import { toggleRegister, isRegistered } from "@/composables/Opp/oppRequest";
import { isInMyOpps, toggleMyOpps } from "@/composables/Profile/modifyMyOpps";
import {createToast} from "mosha-vue-toastify";
import 'mosha-vue-toastify/dist/style.css';

export default {
  props: ["id"],
  async setup(props) {
    const { user } = getUser();
    const router = useRouter();
    const data = ref(null);
    const profile = ref(null);
    const registerFlag = ref(null);
    const isLoaded = ref(false);

    onMounted(async () => {
      registerFlag.value = await isRegistered(user.value.uid, props.id);
      await getOpp(data, props.id);
      await getProfile(profile, data.value.alumniId);
      isLoaded.value = true;
    });

    const handleBack = () => {
      router.go(-1);
    };

    const submitAlert = () => {
      createToast({
        title: "Success!",
        description: 'Your request is processed and saved.'
      }, {
        type: "success"
      })
    }

    const handleClick = async () => {
      submitAlert()
      await toggleMyOpps(user.value.uid, props.id, "pending");
      await toggleRegister(user.value.uid, props.id, registerFlag);
    };

    const modifyDeadline = (deadline) => {
      return (
        String(deadline.toDate().getMonth() + 1).padStart(2, "0") +
        "/" +
        String(deadline.toDate().getDate()).padStart(2, "0") +
        "/" +
        deadline.toDate().getFullYear()
      );
    };

    return {
      isLoaded,
      user,
      data,
      profile,
      registerFlag,
      handleBack,
      isRegistered,
      toggleRegister,
      submitAlert,
      handleClick,
      modifyDeadline,
    };
  },
};
</script>

<style scoped>
.referral-detail {
  padding: 5vh 8vh;
  width: 100%;
  animation: fadein 1s;
}

@media screen and (max-width: 630px) {
  .referral-detail {
    padding: 2vh 4vh;
  }
}

.detail-mid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 3vh 0;
}

.detail-mid h1,
.detail-mid h3,
.detail-mid h4 {
  margin-top: 1vh;
}

.ref-info {
  flex: 0 1 auto;
}

.ref-empty {
  flex: 1 1 auto;
}

.ref-info h1,
.ref-info h3,
.ref-info h4{
  width:100%;
}

.ref-request {
  padding-top: 20px;
}

.ref-request button {
  font-size: calc(10px + 1vw);
  color: white;
  height: 9vh;
  width: 35vw;
  max-width: 300px;
  background: var(--purple-l);
  border: none;
  border-radius: 3.5vh;
  transition: 0.3s ease-out;
}

.ref-request button:hover {
  cursor: pointer;
  background: var(--purple);
}

.withdrawBtn {
  background: linear-gradient(
    180deg,
    rgb(255, 150, 150) 0%,
    rgb(252, 91, 91) 100%
  ) !important;
}

.withdrawBtn:hover {
  background: linear-gradient(
    180deg,
    rgb(255, 120, 120) 0%,
    rgb(255, 40, 40) 100%
  ) !important;
}

.ref-descriptions {
  margin-top: 5vh;
  list-style: none;
}

.ref-description {
  margin-top: 3vh;
}

.ref-description h4 {
  font-weight: normal;
  white-space: pre-wrap;
}

.ref-description h4 p {
  display: inline;
  flex-wrap: wrap;
}
</style>
